<template>
  <el-dialog :width="400" @close="handleCancel(formRef)">
    <template #title custom-class="title">
      <div class="title">Enter amount</div>
    </template>
    <el-form
      ref="formRef"
      :model="formModel"
      :rules="rules"
      @submit.native.prevent="handleClick(formRef)"
    >
      <el-form-item prop="money">
        <el-input placeholder="USD" v-model="formModel.money" style="width: 100%" />
      </el-form-item>
      <el-form-item>
        <el-button class="submit" type="primary" @click="handleClick(formRef)" :loading="isLoading">Transfer</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import { reactive, ref } from "vue";
import { addWalletBalance } from '@/api/account'
import { ElMessage } from "element-plus";

const formRef = ref()
const formModel = reactive({
  money: undefined
})

const rules = reactive({
  money: [{
    required: true,
    message: 'Amount is required',
    trigger: 'blur'
  }, {
    pattern: /^[1-9]+\d*(\.\d{0,2})?$|^0?\.\d{0,2}$/,
    message: 'Amount needs to be greater than 0, supports two decimals',
    trigger: 'blur'
  }]
})

const $emit = defineEmits(['cancel'])
const handleCancel = (ref) => {
  formModel.money = undefined
  $emit('cancel')
  ref.resetFields()
}

// 跳转到 paypal
const isLoading = ref(false)
const handleClick = (ref) => {
  if (!ref) return
  ref.validate(async (val) => {
    if (val) {
      isLoading.value = true
      try {
        const { data } = await addWalletBalance(Number(formModel.money))
        window.location.href = data.approveUrl
        handleCancel(ref)
      } catch (e) {
        ElMessage.error(e)
      } finally {
        isLoading.value = false
      }
    } else {
      return false
    }
  })
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 24px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
}
.submit {
  width: 100%;
  margin-top: 5px;
}
</style>