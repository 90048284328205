<template>
  <el-dialog :width="width600" :before-close="handleClose">
    <template #title custom-class="title">
      <div class="title">Change your email</div>
    </template>
    <div class="flex form_box">
      <el-form ref="formRef"  :model="form" :rules="rules" class="form">
        <div class="name">E-mail</div>
        <el-form-item prop="email">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <div class="name">Verify code</div>
        <el-form-item prop="verifyCode">
          <el-input v-model="form.verifyCode">
            <template #suffix>
              <el-button type="primary" @click="sendCode" :disabled="!show">
                <span v-show="show">Send verification code</span>
                <span v-show="!show">{{ count }} s</span>
              </el-button>
            </template>
          </el-input>
        </el-form-item>
        <div class="name">Current password</div>
        <el-form-item prop="password">
          <el-input v-model="form.password" show-password></el-input>
        </el-form-item>
      </el-form>
      <div class="btn_save">
        <el-button @click="save" class="save" type="info" :loading="isLoading">Save</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { sendVerifyCode, updateUserEmail } from '@/api/account.js'

const TIME_COUNT = 60;
export default {
  emits: ["input", "refresh"],
  data() {
    // /(?=.*[A-Z])(?=.*[A-Za-z])(?=.*[0-9])/     确保有一个数字跟大写字母
    // 邮箱验证
    let validatePass = (rule, value, callback) => {
      let partten = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      let re = new RegExp(partten);
      if (value) {
        if (!re.test(value)) {
          callback(new Error("Please enter the correct mailbox"));
        } else {
          callback()
        }
      }
    };
    let setPassword = (rule, value, callback) => {
      if (value) {
        let partten = /(?=.*[A-Z])/;
        let num = /(?=.*[A-Za-z])(?=.*[0-9])/;
        let re = new RegExp(partten);
        if (value) {
          // 确保有一个大写字母
          if (!re.test(value)) {
            callback(new Error("At least one uppercase character"));
            // 确保有一个数字
          } else if (!num.test(value)) {
            callback(new Error("At least one number"));
          } else {
            callback()
          }
        }
      }
    };
    return {
      form: {
        email: "",
        verifyCode: "",
        password: "",
      },
      rules: {
        email: [
          {
            required: true,
            message: "Please enter email address",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "blur" },
        ],
        verifyCode: [
          {
            required: true,
            message: "Please enter verify code",
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: "Please input a password",
            trigger: "blur",
          },
          {
            min: 8,
            message: "8 characters minimum",
            trigger: "blur",
          },
          { validator: setPassword, trigger: "blur" },
        ],
      },
      show: true, // 是否启用按钮
      count: '', // 初始次数
      timer: null,
      isLoading: false // 按钮加载的样式
    };
  },
  methods: {
    save() {
      this.$refs.formRef.validate(async (val) => {
        if (val) {
          this.isLoading = true
          try {
            this.form.password = this.$encryption(this.form.password)
            const { status, message } = await updateUserEmail(this.form)
            if (status === 'fail') {
              this.$message.error(message)
            } else {
              this.$emit('refresh')
              this.$message.success(message + ' Please log in again.')
            }
          } catch (e) {
            this.$message.error(e)
          }
          this.isLoading = false
          this.handleClose();
        }
      });
    },
    handleClose() {
      this.$emit("input", false);
      this.$refs.formRef.resetFields();
      this.count = null;
      this.form = {
        email: "",
        verifyCode: "",
        password: "",
      };
    },
    // 发送验证码
    sendCode() {
      this.$refs.formRef.validateField(['email'], async (err) => {
        if (err) {
          return false
        } else {
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);  // 清除定时器
                this.timer = null;
              }
            }, 1000)
          }
          try {
            const { message } = await sendVerifyCode(this.form)
            this.$message.success(message)
          } catch (e) {
            this.$message.error(e)
          }
        }
      })
    }
  },
};
</script>
<script setup>
import { defineComponent, reactive, ref } from "vue";

const width600 = ref("600px");

const props = defineProps({});
</script>

<style scoped lang="scss">
:deep(.el-input__icon) {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.4);
}
:deep(.el-dialog__header) {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.85);
}
:deep(.el-input__suffix) {
  right: 0;
}
.title {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.85);
}

.form_box {
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px;
  .form {
    margin-bottom: 30px;

    .name {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
    }
  }
  .btn_save {
    display: flex;
    justify-content: flex-end;
    .save {
      height: 40px;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
</style>
