<script setup>
import { defineComponent, reactive, toRefs, onMounted, ref } from "vue";
import { loadStripe } from "@stripe/stripe-js";
import { ElMessage } from "element-plus";
import { stripeSecret, stripeSetupIntent, updateCreditCard  } from "@/api/account.js";

const emit = defineEmits(["close", "update"]);

const props = defineProps({
  updateStatus: {
    type: Boolean,
    default: false,
  },
});

let handleClose = () => {
  emit("close");
  cardError.value = "";
  loading.value = false;
};

onMounted(() => {});
let clientSecret = ref("");
let getStripeSecret = async () => {
  try {
    const { data } = await stripeSecret();
    clientSecret.value = data?.clientSecret;
  } catch (error) {
    ElMessage.error(error);
  }
};

let open = () => {
  initStripe();
  getStripeSecret();
};

let cardError = ref("");
let cardElement = ref("");

let stripe = ref("");

let loading = ref(false);

let initStripe = async () => {
  
  stripe.value = await loadStripe(process.env.VUE_APP_CODE_STRIPE); //dev

  var style = {
    base: {
      color: "#32325D",
      fontWeight: 500,
      fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
      fontSize: "16px",
      fontSmoothing: "antialiased",

      "::placeholder": {
        color: "#CFD7DF",
      },
      ":-webkit-autofill": {
        color: "#e39f48",
      },
    },
    invalid: {
      color: "#E25950",

      "::placeholder": {
        color: "red",
      },
    },
  };

  let elements = stripe.value.elements({
    locale: "US",
  });
  cardElement.value = elements.create("card", {
    style: style,
    // iconStyle: "solid",
  });
  cardElement.value.mount("#card-element");

  cardElement.value.addEventListener("change", function (event) {
    if (event.error) {
      cardError.value = event.error.message;
    } else {
      cardError.value = "";
    }
  });
};

let present = () => {
  // stripe.value.createSource(cardElement.value ).then((result) => {
  //   if (result.error) {
  //     cardError.value = result.error.message;
  //   } else {
  //      console.log("createSource success");
  //      console.log(result);
  //   }
  // });
   
 loading.value = true
  stripe.value
    .confirmCardSetup(clientSecret.value, {
      payment_method: {
        card: cardElement.value,
      },
    })
    .then(async (result) => {
      if (result.error) {
         loading.value = false
        cardError.value = result.error.message;
      } else {
        
        if (result?.setupIntent?.status === "succeeded") {
          if (!!props.updateStatus) {
            //更改信用卡

            try {
              const { message } = await updateCreditCard({
                paymentMethodId: result.setupIntent.payment_method,
              });
              ElMessage.success(message);
            } catch (error) {
              console.log(error)
              ElMessage.error(error);
            }
          } else {
            // 新增信用卡

            try {
              const { message } = await stripeSetupIntent({
                paymentMethodId: result.setupIntent.payment_method,
              });
              ElMessage.success(message);
            } catch (error) {
              ElMessage.error(error);
            }
          }
          handleClose();
          emit("update");
          loading.value = false;
        }
      }
    });
};
</script>

<template>
  <el-dialog
    @open="open"
    title="Set up a credit / debit card"
    width="500px"
    :destroy-on-close="true"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="card">
      <div id="card-element"></div>
      <div id="card-errors">{{ cardError }}</div>
      <el-button
        type="primary"
        style="width: 400px"
        @click="present"
        :loading="loading"
        >Pay</el-button
      >
    </div>

    <!-- <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary"  :loading="loading"
        >确 定</el-button
      >
    </span> -->
  </el-dialog>
</template>

<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: #6772e5;
  padding: 30px;
}

#card-element {
  padding: 10px;
  width: 400px;
  border-style: none;
  // background-color: #eee;
  box-shadow: 0 6px 9px rgb(50 50 93 / 6%), 0 2px 5px rgb(0 0 0 / 8%),
    inset 0 1px 0 #eee;
  border-radius: 4px;
}
#card-errors {
  color: red;
  margin-bottom: 20px;
  text-align: left;
  width: 400px;
  margin-top: 5px;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
