<template>
  <div class="changePassword_box">
    <div class="flex w1120">
      <div class="info_box">
        <div class="info">Delete account</div>
        <div class="account_info">You can delete your 99diy account.</div>
      </div>
      <div class="change">
        <div class="delete">
          Permanently delete my 99diy account and all my data. This cannot be
          undone.
        </div>
        <div>
          <el-button class="delete_btn" @click="deleteBtn">Delete account</el-button>
        </div>
      </div>
    </div>
  </div>
  <delete-info v-model="deleteInfoShow" @input="deleteClose" @check="handleShow"/>
  <delete-check v-model="deleteCheckShow" @input="handleShow"></delete-check>
</template>

<script setup>
import { ref } from "vue";
import DeleteInfo from "./deleteInfo.vue";
import DeleteCheck from './deleteCheck.vue'

const deleteInfoShow = ref(false)
const deleteCheckShow = ref(false)

const deleteBtn = () => {
  deleteInfoShow.value = true
}

const deleteClose = () => {
  deleteInfoShow.value = false
}

const handleShow = (val) => {
  deleteCheckShow.value = val
}
</script>

<style scoped lang="scss">
:deep(.el-form-item) {
  margin-bottom: 30px;
}
.changePassword_box {
  margin-top: 45px;
  .w1120 {
    width: 1120px;
    margin: 0 auto;
  }
  .info_box {
    width: 404px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 22px;
    margin-right: 87px;
    .info {
      font-size: 21px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 29px;
      margin-bottom: 12px;
    }
    .account_info {
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 22px;
    }
  }
  .change {
    flex: 1;
    // background: blue;
    .delete {
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 22px;
    }
    .delete_btn {
      width: 156px;
      height: 40px;
      background: rgba(0, 0, 0, 0.05);
      border-color: rgba(0, 0, 0, 0.02);
      border-radius: 3px;
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      margin-top: 30px;
      margin-bottom: 60px;
    }
  }
}
</style>
