<template>
  <el-dialog :width="width600" :before-close="handleClose">
    <template #title custom-class="title">
      <div class="title">Change your password</div>
    </template>
    <div class="flex form_box">
      <el-form ref="formRef" :model="form" :rules="rules" class="form">
        <div class="name">Current Password</div>
        <el-form-item prop="oldPassword">
          <el-input v-model="form.oldPassword" show-password></el-input>
        </el-form-item>
        <div class="name">New Password</div>
        <el-form-item prop="newPassword">
          <el-input v-model="form.newPassword" show-password></el-input>
        </el-form-item>
      </el-form>
      <div class="icon">
        <i class="icon-mimayaoqiu1 iconfont" v-if="lengthTrue"></i>
        <i class="icon-mimayaoqiu2 iconfont" v-else></i>
        <span :class="{ active: lengthTrue }">8 characters minimum</span>
      </div>
      <div class="icon">
        <i class="icon-mimayaoqiu1 iconfont" v-if="uppercaseTrue"></i>
        <i class="icon-mimayaoqiu2 iconfont" v-else></i>
        <span :class="{ active: uppercaseTrue }"
          >At least one uppercase character</span
        >
      </div>
      <div class="icon">
        <i class="icon-mimayaoqiu1 iconfont" v-if="numberTrue"></i>
        <i class="icon-mimayaoqiu2 iconfont" v-else></i>
        <span :class="{ active: numberTrue }">At least one number</span>
      </div>
      <div class="btn_save">
        <el-button @click="save" class="save" :loading="isLoading">Save</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { updateUserPassword } from '@/api/account.js'
export default {
  emits: ["input","refresh"],
  data() {
    // /(?=.*[A-Z])(?=.*[A-Za-z])(?=.*[0-9])/     确保有一个数字跟大写字母
    let setPassword = (rule, value, callback) => {
      if (!!value) {
        // 确保有一个大写字母
        let partten = /(?=.*[A-Z])/;
        // 确保有一个数字
        let num = /(?=.*[0-9])/;
        this.lengthTrue = value.length > 7;
        this.uppercaseTrue = partten.test(value);
        this.numberTrue = num.test(value);
      } else {
        this.lengthTrue = false;
        this.uppercaseTrue = false;
        this.numberTrue = false;
      }
      callback()
    };
    let setPassword2 = (rule, value, callback) => {
      if (this.lengthTrue && this.uppercaseTrue && this.numberTrue) {
        callback()
      } else {
        callback(new Error('The password format is incorrect'))
      }
    }
    // let setPassword2 = (rule, value, callback) => {
    //   if(value !== this.form.oldPassword) {
    //     callback(new Error('密码不一致'))
    //   } else {
    //     callback()
    //   }
    // };

    return {
      uppercaseTrue: false, //有个大写字母
      lengthTrue: false, //达到八位
      numberTrue: false, //有一位数字
      form: {
        oldPassword: "",
        newPassword: "",
      },
      rules: {
        newPassword: [
          {
            required: true,
            message: "Please enter new password",
            trigger: "blur",
          },
          { 
            validator: setPassword, 
            trigger: "change"
          },
          { 
            validator: setPassword2,
            trigger: "blur" 
          },
        ],
        oldPassword: [
          {
            required: true,
            message: "Please enter current password",
            trigger: "blur",
          },
          { 
            validator: setPassword, 
            trigger: "change"
          },
          { 
            validator: setPassword2,
            trigger: "blur" 
          },
        ],
      },
      isLoading: false // 按钮加载的样式
    };
  },
  methods: {
    save() {
      this.$refs.formRef.validate((val) => {
        let flag = this.lengthTrue && this.numberTrue && this.uppercaseTrue && val
        if (flag) {
          this.updatePassword()
          this.handleClose();
        }
      });
    },
    handleClose() {
      this.$emit("input", false);
      this.$refs.formRef.resetFields();
      this.form = {
        oldPassword: "",
        newPassword: "",
      };
      this.lengthTrue = false;
      this.uppercaseTrue = false;
      this.numberTrue = false;
    },
    // 更改用户密码
    async updatePassword() {
      try {
        this.isLoading = true
        this.form.oldPassword = this.$encryption(this.form.oldPassword)
        this.form.newPassword = this.$encryption(this.form.newPassword)
        const { status, message } = await updateUserPassword(this.form)
        if (status === 'fail') {
          this.$message.error(message) 
        } else {
          this.$emit('refresh')
          this.$message.success(message + ' Please log in again.')
        }
      } catch (e) {
        this.$message.error(e)
      }
      this.isLoading = false
    }
  },
};
</script>
<script setup>
import { defineComponent, reactive, ref } from "vue";

const width600 = ref("600px");

const props = defineProps({});
</script>

<style scoped lang="scss">
$ba0_4: rgba(0, 0, 0, 0.85);
:deep(.el-input__icon) {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.4);
}
:deep(.el-dialog__header) {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: $ba0_4;
}
.title {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: $ba0_4;
}

.form_box {
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px;
  .icon {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 20px;
    margin-bottom: 8px;
    .iconfont {
      margin-right: 4px;
    }
    .icon-mimayaoqiu1 {
      color: #1eab7f;
    }
  }
  .form {
    margin-bottom: 0px;

    .name {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 600;
      color: $ba0_4;
      line-height: 22px;
    }
  }
  .btn_save {
    display: flex;
    justify-content: flex-end;
    .save {
      height: 40px;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      font-size: 16px;
      font-weight: 600;
      color: $ba0_4;
    }
  }
}

.active {
  color: $ba0_4;
}
</style>
