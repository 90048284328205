<template>
  <el-dialog :width="320" :before-close="handleCancel">
    <template #title custom-class="title">
      <div class="title">Check your email</div>
    </template>
    <div class="info_box">
      Your request has been received. In order to verify your identity, we have sent an email to your connected email address. Please check your email for further instructions.
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="info" @click="handleCancel">Got it</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
const $emit = defineEmits(['input'])
const handleCancel = () => {
  $emit('input', false)
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.85);
}
.info_box {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  padding: 0 10px;
}
</style>
