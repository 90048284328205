<template>
  <div class="changePassword_box">
    <div class="flex w1120">
      <div class="info_box">
        <div class="info">Address management</div>
        <div class="account_info">
          <div class="mb-30">
            You can manage your return to sender address and billing address in
            this section.
          </div>
          <div>
            For example, you can now edit your Return to Sender address and your
            business name will be printed on the packaging.
          </div>
        </div>
      </div>
      <div class="change">
        <div class="flex">
          <div class="credit_debit mr_10">
            <div class="your_address">Your address</div>
            <div class="flex flex-col">
              <div class="your_address_info">
                This address will be used to sending you product samples, and
                return address.
              </div>
              <div class="edit">
                <el-button class="edit_btn" @click="handleClick(0)">Edit</el-button>
              </div>
            </div>
          </div>
          <div class="credit_debit ml_10">
            <div class="your_address">Billing address</div>
            <div class="flex flex-col">
              <div class="your_address_info">
                This address will be used for the billing address
              </div>
              <div class="edit">
                <el-button class="edit_btn" @click="handleClick(1)">Edit</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <change-address :objType="objType" v-model="changeAddressShow" @input="addressClose" ref="address"/>
</template>

<script setup>
import changeAddress from "./changeAddress.vue";
</script>

<script>
export default {
  data() {
    return {
      changeAddressShow: false,
      objType:''
    }
  },
  mounted() {
    this.$store.dispatch('address/addressAction')
  },
  methods: {
    // 关闭弹窗
    addressClose() {
      this.objType = ''
      this.changeAddressShow = false
    },
    // 处理地址编辑点击
    handleClick(type) {
      this.changeAddressShow = true
      // this.$refs.address.getAddress(type)
      this.objType = type
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.el-form-item) {
  margin-bottom: 30px;
}
.changePassword_box {
  margin-top: 45px;
  .w1120 {
    width: 1120px;
    margin: 0 auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .info_box {
    width: 404px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 22px;
    margin-right: 87px;
    .info {
      font-size: 21px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 29px;
      margin-bottom: 12px;
    }
    .account_info {
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 22px;
    }
    .mb-30 {
      margin-bottom: 30px;
    }
  }
  .change {
    flex: 1;
    // background: blue;
    .mr_10 {
      margin-right: 10px;
    }
    .ml_10 {
      margin-left: 10px;
    }
    .credit_debit {
      height: 219px;
      flex: 1;
      background: #ffffff;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.08);
      padding: 20px 20px 20px 15px;
      margin-bottom: 40px;
      .flex-col {
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
      }
      .your_address {
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        margin-bottom: 12px;
      }
      .your_address_info {
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
        // margin-bottom: 40px;
      }
      .edit {
        // display: flex;
        // justify-content: flex-end;
        .edit_btn {
          width: 70px;
          height: 40px;
          background: rgba(0, 0, 0, 0.05);
          border-color: rgba(0, 0, 0, 0.02);
          border-radius: 3px;
          font-size: 16px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }
}
</style>
