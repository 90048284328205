<template>
  <el-dialog :width="width600" :before-close="handleClose">
    <template #title custom-class="title">
      <div class="title">Edit address</div>
    </template>
    <div class="flex form_box">
      <el-form ref="formRef" :model="form" :rules="rules" class="form" v-loading="formLoading">
        <el-row :gutter="30">
          <el-col :span="12">
            <div class="name">Full name <span class="requied">*</span></div>
            <el-form-item prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div class="name">Phone number <span class="requied">*</span></div>
            <el-form-item prop="phone">
              <el-input v-model="form.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div class="name">Country <span class="requied">*</span></div>
            <el-form-item prop="country">
              <el-select v-model="form.country" @change="getStateList(1)">
                <el-option v-for="item in countryList" :label="item.name" :value="item.code" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div class="name">
              State / Province <span class="requied">*</span>
            </div>
            <el-form-item prop="state">
              <el-select v-model="form.state" @focus="getStateList()" @change="getCityList(1)">
                <el-option v-for="item in stateList" :label="item.name" :value="item.name" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div class="name">City</div>
            <el-form-item prop="city">
              <el-select v-model="form.city" @focus="getCityList()">
                <el-option v-for="item in cityList" :label="item.name" :value="item.name" :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div class="name">Postal / ZIP Code</div>
            <el-form-item prop="code">
              <el-input v-model="form.code"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <div class="name">Detiled Address</div>
            <el-form-item prop="address1">
              <el-input v-model="form.address1"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col>
            <div class="name">Address 2</div>
            <el-form-item prop="address2">
              <el-input v-model="form.address2"></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
      <div class="btn_save">
        <el-button @click="save" class="save" :loading="isLoading">Save</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { saveAddress, getAddressList, createAddress } from '@/api/account.js'
export default {
  emits: ["input"],
  props:{
    objType:String | Number | null
  },
  data() {
    return {
      form: {
        addressId: null,
        name: null,
        phone: null,
        state: null,
        country: null,
        city: null,
        code: null,
        address1: null,
        address: null
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please enter full name",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "Please enter phone number",
            trigger: "blur",
          }
        ],
        country: [
          {
            required: true,
            message: "Please select country",
            trigger: "blur",
          },
        ],
        state: [
          {
            required: true,
            message: "Please select state",
            trigger: "blur",
          },
        ],
      },
      countryList: [], // 国家列表
      stateList: [], // 州 / 省列表
      cityList: [], // 城市列表
      params: {}, // 传递的参数
      isLoading: false, // 按钮加载的样式
      formLoading: false, // 加载动画
      type: 0 // 地址类型
    };
  },
  methods: {
    // 保存编辑
    save() {
      this.$refs.formRef.validate(async (val) => {
        if (val) {
          let { addressId, name: fullName, phone: phoneNumber, country, state: stateOrProvince, city, code: postal, address1, address2} = this.form
          this.isLoading = true
          if (!city) {
            city = stateOrProvince
          }
          this.params = {
            addressId,
            fullName,
            phoneNumber,
            country,
            stateOrProvince,
            city,
            postal,
            address1,
            address2
          }
          try {
            if (addressId) {
              const { status, message } = await saveAddress(this.params)
              if (status === 'fail') {
                this.$message.error(message)
              } else {
                this.$message.success(message)
              }
              this.$message.success(message)
            } else {
              const { status, message } = await createAddress({ type: this.type, ...this.params })
              if (status === 'fail') {
                this.$message.error(message)
              } else {
                this.$message.success(message)
              }
              this.$message.success(message)
            }
          } catch (e) {
            this.$message.error(e)
          }
          this.isLoading = false
          this.handleClose();
        }
      });
    },
    // 获取地址信息 回显
    async getAddress(type) {
      try {
        this.formLoading = true
        this.type = type
        const { data } = await getAddressList(type)
        // 获取全球区域信息
        const areaInfo = this.$store.state.address.areaInfo
        this.countryList = areaInfo
        if (data.length) {
          const { id: addressId, first_name: name, phone, state, country, city, zip: code, address1, address2 } = data[0]
          this.form = {
            addressId,
            name,
            phone,
            state,
            country,
            city,
            code,
            address1,
            address2
          }
        }
        this.formLoading = false
      } catch(e) {
        return e
      }
    },
    // 处理取消弹窗
    handleClose() {
      this.$emit("input", false);
      this.$refs.formRef.resetFields();
    },
    // 获取 州 / 省列表
    getStateList(clear) {
      this.stateList = this.countryList.find(item => item.code === this.form.country).states
      // 清空 state 和 city
      if (clear) {
        this.form.state = ""
        this.form.city = ""
      }
    },
    // 获取城市列表
    getCityList(clear) {
      this.cityList = this.stateList.find(item => item.name === this.form.state).cities
      // 清空 city
      if (clear) {
        this.form.city = ""
      }
    }
  },
  watch: {
    objType() {
      this.getAddress(this.objType)
    }
  }
};
</script>
<script setup>
import { defineComponent, reactive, ref } from "vue";

const width600 = ref("600px");
</script>

<style scoped lang="scss">
:deep(.el-select) {
  width: 100%;
}
.title {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.85);
}

.form_box {
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px;
  .form {
    margin-bottom: 10px;

    .name {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
    }
    .requied {
      color: #ff0000;
    }
  }
  .btn_save {
    display: flex;
    justify-content: flex-end;
    .save {
      height: 40px;
      background: rgba(0, 0, 0, 0.05);
      border-color: rgba(0, 0, 0, 0.02);
      border-radius: 3px;
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
</style>
