<script setup>
import Store from "store";
import changeEmail from "./changeEmail.vue";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import ChangePassword from "./changePassword.vue";
import { getUserInfo, editUserInfo } from "@/api/account";

//#region  邮箱
let changeEmailShow = ref(false);

let form = ref({
  fullName: "",
  email: "",
  phone: "",
  password: "12345678",
});

let rules = {
  fullName: [
    {
      required: true,
      message: "please enter username",
      trigger: "blur",
    },
  ],
  // phone: [
  //   {
  //     required: true,
  //     message: "please enter phone",
  //     trigger: "blur",
  //   },
  // ]
};

let formRef = ref("");

let btnShow = ref(false);
let isLoading = ref(false);

let userInfo = ref({});

let getuserInfo = async () => {
  const { data } = await getUserInfo();
  userInfo.value = data;
  form.value.fullName = data.fullName;
  form.value.phone = data.phone;
  form.value.email = data.email;
};

onMounted(() => {
  // if (JSON.stringify(userInfo.value) === "{}") {
  //   setTimeout(() => {
  //     getUserInfoList();
  //   }, 1000);
  // } else {
  //   getUserInfoList();
  getuserInfo();
  // }
});

const store = useStore();

// let userInfo = computed(() => {
//   return store.state.user.userInfo;
// });

const setChangeEmail = () => {
  changeEmailShow.value = true;
};

const emailClose = () => {
  changeEmailShow.value = false;
};

//#endregion

//#region  密码

let changePasswordShow = ref(false);

const passwordClose = () => {
  changePasswordShow.value = false;
};

const setChangePassword = () => {
  changePasswordShow.value = true;
};

//#endregion

// 获取用户信息

function changeDetails() {
  btnShow.value = true;
}
function cancel() {
  form.value.fullName = userInfo.value.fullName
  form.value.phone = userInfo.value.phone;
  form.value.email = userInfo.value.email;
  btnShow.value = false;
}

  // 重新登录
  let handleExitClick = () => {
    Store.remove("diyToken");
    window.location.href = process.env.VUE_APP_URL_HOME + "/login";
  }

// 编辑用户信息
function saveUserInfo() {
  formRef.value.validate(async (val) => {
    if (val) {
      isLoading.value = true;
      try {
        const { message } = await editUserInfo({
          fullName: form.value.fullName,
          phone: form.value.phone,
        });
        btnShow.value = false;
        isLoading.value = false;
        ElMessage.success(message);
        getuserInfo()
      } catch (e) {
        console.log(e);
        ElMessage.error(e);
      }
    }
  });


}
</script>

//
<script>
// import Store from "store";
// import { editUserInfo } from "@/api/account.js";
// import { ElMessage } from "element-plus";
// export default {
//   data() {
//     return {
//       form: {
//         fullName: "",
//         email: "",
//         phone: "",
//         password: "12345678",
//       },
//       rules: {
//         fullName: [
//           {
//             required: true,
//             message: "please enter username",
//             trigger: "blur",
//           },
//         ],
//         // phone: [
//         //   {
//         //     required: true,
//         //     message: "please enter phone",
//         //     trigger: "blur",
//         //   },
//         // ]
//       },
//       btnShow: false,
//       isLoading: false, // 按钮加载样式
//     };
//   },
//   mounted() {
//     if (JSON.stringify(this.userInfo) === "{}") {
//       setTimeout(() => {
//         this.getUserInfoList();
//       }, 1000);
//     } else {
//       this.getUserInfoList();
//     }
//   },
//   computed: {
//     userInfo() {
//       return this.$store.state.user.userInfo;
//     },
//   },
//   methods: {
//     // 获取用户信息
//     async getUserInfoList() {
//       this.form.fullName = this.userInfo.fullName;
//       this.form.phone = this.userInfo.phone;
//       this.form.email = this.userInfo.email;
//     },

//     changeDetails() {
//       this.btnShow = true;
//     },
//     cancel() {
//       this.form.fullName = this.userInfo.fullName;
//       this.form.phone = this.userInfo.phone;
//       this.form.email = this.userInfo.email;
//       this.btnShow = false;
//     },

//     // 编辑用户信息
//     saveUserInfo() {
//       this.$refs.form.validate(async (val) => {
//         if (val) {
//           this.isLoading = true;
//           try {
//             const { message } = await editUserInfo({
//               fullName: this.form.fullName,
//               phone: this.form.phone,
//             });
//             this.getUserInfoList();
//             this.btnShow = false;
//             this.isLoading = false;
//             this.$message.success(message);
//           } catch (e) {
//             this.$message.error(e);
//           }
//         }
//       });
//     },
//     // 重新登录
//     handleExitClick() {
//       Store.remove("diyToken");
//       window.location.href = process.env.VUE_APP_URL_HOME + "/login";
//     },
//   },
// };
//
</script>

<template>
  <div class="changePassword_box">
    <div class="flex w1120">
      <div class="info_box">
        <div class="info">Personal information</div>
        <div class="account_info">
          You can find your account related information here.
        </div>
      </div>
      <div class="change">
        <el-form ref="formRef" :model="form" :rules="rules">
          <div class="name">Full name</div>
          <el-form-item prop="fullName">
            <el-input v-model="form.fullName" :disabled="!btnShow"></el-input>
          </el-form-item>
          <div class="name">E-mail</div>
          <el-form-item prop="email">
            <el-input v-model="form.email" disabled></el-input>
            <div class="change" @click="setChangeEmail" v-if="btnShow">
              Change
            </div>
          </el-form-item>
          <div class="name">Phone</div>
          <el-form-item prop="phone">
            <el-input v-model="form.phone" :disabled="!btnShow"></el-input>
          </el-form-item>
          <div class="name">Password</div>
          <el-form-item prop="password">
            <el-input
              v-model="form.password"
              type="password"
              disabled
            ></el-input>
            <div class="change" v-if="btnShow" @click="setChangePassword">
              Change
            </div>
          </el-form-item>
          <div v-if="!btnShow">
            <el-button class="btn_color" type="info" @click="changeDetails"
              >Change details</el-button
            >
          </div>
          <div v-else>
            <el-button
              class="saveChanges"
              @click="saveUserInfo"
              :loading="isLoading"
              >Save changes</el-button
            >
            <el-button @click="cancel" class="cancel" type="info"
              >Cancel</el-button
            >
          </div>
        </el-form>
      </div>
    </div>
  </div>
  <!-- 修改邮箱 -->
  <change-email
    v-model="changeEmailShow"
    @input="emailClose"
    @refresh="handleExitClick"
  />
  <!-- 修改密码 -->
  <change-password
    v-model="changePasswordShow"
    @input="passwordClose"
    @refresh="handleExitClick"
  />
</template>

<style scoped lang="scss">
:deep(.el-form-item) {
  margin-bottom: 30px;
}
:deep(.el-input.is-disabled .el-input__inner) {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.65);
}
.changePassword_box {
  margin-top: 45px;
  .w1120 {
    width: 1120px;
    margin: 0 auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .info_box {
    width: 404px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 22px;
    margin-right: 87px;
    .info {
      font-size: 21px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 29px;
      margin-bottom: 12px;
    }
    .account_info {
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 22px;
    }
  }
  .change {
    flex: 1;
    // background: blue;
    .name {
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      margin-bottom: 8px;
    }
    .btn_color {
      width: 155px;
      height: 40px;
      background: rgba(0, 0, 0, 0.05) !important;
      border-color: rgba(0, 0, 0, 0.02) !important;
      border-radius: 3px;
      margin-bottom: 40px;
    }
    .change {
      position: absolute;
      right: 0;
      top: 30px;
      cursor: pointer;
      color: #1eab7f;
    }
    .cancel {
      width: 90px;
      height: 40px;
      background: rgba(0, 0, 0, 0.05);
      border-color: rgba(0, 0, 0, 0.02);
      border-radius: 3px;
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
    .saveChanges {
      // width: 146px;
      height: 40px;
      background: #ff2379;
      border-radius: 3px;
      font-size: 16px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.85);
      margin-bottom: 40px;
    }
  }
}
</style>
