<template>
  <div class="changePassword_box">
    <div class="flex w1120">
      <div class="info_box">
        <div class="info">Payment details</div>
        <div class="account_info">
          <div class="mb-30">
            Your credit card information is stored by our payment provider and
            used solely for costs related to 99diy fulfillment services. Your
            information will not be shared.
          </div>

          <div>
            These settings will apply globally to your 99diy account and to all
            stores and products
          </div>
        </div>
      </div>
      <div class="change">
        <div class="balance">
          <div class="current_balance">Current balance</div>
          <div class="USD">
            <span class="text_USD">{{ currency }}</span
            ><span>{{ wallet }}</span>
          </div>
        </div>
        <div class="elaborate">
          <div>Add a credit/debit card or add a balance from PayPal，</div>
          <div>to allow production of orders.</div>
        </div>
        <div class="flex">
          <div>
            <!-- 未绑定信用卡 -->
            <!--  -->
            <template v-if="!cardInfo?.last4">
              <div
                class="credit_debit_PayPal cursor mr_10 "
                title="under development...."
                @click="addUpCredit"
              >
                <div class="add">
                  <i class="icon-a-lianhe15 iconfont"></i>
                </div>
                <div class="text">Set up a credit / debit card</div>
              </div>
            </template>

            <!-- 已绑定信用卡 -->
            <template v-else>
              <div class="credit_debit_PayPal mr_10" style="padding: 20px">
                <div class="brand">
                  {{ cardInfo?.brand }}
                </div>
                <div class="flex flex_space">
                  <div class="last_name">
                    {{ cardInfo?.last4 }}
                  </div>
                  <div>
                    <el-image :src="cardInfo.icon"></el-image>
                  </div>
                </div>
                <div class="flex flex_space">
                  <el-button
                    @click="updateCard"
                    type="primary"
                    style="width: 124px"
                    >Change Card</el-button
                  >
                  <el-button
                    type="info"
                    style="width: 115px; margin-left: 20px"
                    @click="unbundle"
                    >Delete Card</el-button
                  >
                </div>
              </div>
            </template>
          </div>
          <div class="credit_debit_PayPal cursor ml_10" @click="handleBalance">
            <div class="add">
              <i class="icon-a-lianhe15 iconfont"></i>
            </div>
            <div class="text">Add balance from PayPal</div>
          </div>
        </div>
        <div class="info">No upfront costs needed for credit/debit card.</div>
        <div class="balance_info">
          Cost to produce and ship products will be deducted from your balance
          only after a production request has been received. If the balance is
          not sufficient, the remaining amount of money will be charged from
          your credit/debit card.
        </div>
      </div>
    </div>
  </div>
  <add-balance v-model="addDialog" @cancel="handleCancel"></add-balance>
  <set-up-credit
    v-model="setUpCreditShow"
    @close="close"
    @update="getUserWallet"
    :updateStatus="updateStatus"
  />
</template>

<script setup>
import { onMounted, ref, reactive } from "vue";
import {
  getUserWalletInfo,
  creditCardInfo,
  unbindCreditCard,
} from "@/api/account";
import { ElMessage, ElMessageBox } from "element-plus";
import AddBalance from "./addBalance.vue";
import setUpCredit from "./setUpCredit.vue";

const wallet = ref(0);
const currency = ref("USD");

onMounted(() => {
  getUserWallet();
  // getCreditCardInfo();
});

//已绑定信用卡信息展示
let cardInfo = reactive({
  brand: "",
  last4: "",
  icon: "",
});

// 获取钱包余额
const getUserWallet = async () => {
  try {
    const { data } = await getUserWalletInfo();
    wallet.value = data.wallet;
    currency.value = data.currency;
    cardInfo.brand = data?.cardInfo?.brand;
    cardInfo.last4 = data?.cardInfo?.last4;
    cardInfo.icon = data?.cardInfo?.logo;
  } catch (e) {
    ElMessage.error(e);
  }
};


// let getCreditCardInfo = async () => {
//   try {
//     const { data } = await creditCardInfo();
//     cardInfo.brand = data?.brand;
//     cardInfo.last4 = data?.last4;
//     cardInfo.icon = data?.logo;
//   } catch (error) {
//     ElMessage.error(error);
//   }
// };

//更改信用卡
let updateStatus = ref(false);
let updateCard = () => {
  setUpCreditShow.value = true;
  updateStatus.value = true;
};

//解绑
let unbundle = async () => {
  ElMessageBox.confirm(
    `Are you sure you want to delete your credit card?
    After deletion, we cannot automatically synchronize the order to the factory for you, and you need to manually complete the payment before synchronizing the order for you.`,
    "Delete your card?",
    {
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",

      beforeClose: async (action, instance, done) => {
        if (action === "confirm") {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = "Loading...";
          try {
            const { message } = await unbindCreditCard();
            await getUserWallet();
            ElMessage.success(message);
          } catch (error) {
            ElMessage.error(error);
          }
          instance.confirmButtonLoading = false;
          done();
        } else {
          done();
        }
      },
    }
  ).catch((_) => {
    return false;
  });
};

//信用卡 setUpCreditShow
let setUpCreditShow = ref(false);

let addUpCredit = () => {
  setUpCreditShow.value = true;
};

let close = () => {
  setUpCreditShow.value = false;
};

// 充值余额弹窗
const addDialog = ref(false);
const handleBalance = () => {
  addDialog.value = true;
};

const handleCancel = () => {
  addDialog.value = false;
};
</script>

<style scoped lang="scss">
.brand {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  margin-bottom: 12px;
}
.flex_space {
  justify-content: space-between;
}

.last_name {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  margin-bottom: 40px;
}

.cursor {
  cursor: pointer;
}
:deep(.el-form-item) {
  margin-bottom: 30px;
}
.changePassword_box {
  margin-top: 45px;
  .w1120 {
    width: 1120px;
    margin: 0 auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .info_box {
    width: 404px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 22px;
    margin-right: 87px;
    .info {
      font-size: 21px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 29px;
      margin-bottom: 12px;
    }
    .account_info {
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 22px;
      .mb-30 {
        margin-bottom: 30px;
      }
    }
  }
  .change {
    flex: 1;
    .mr_10 {
      margin-right: 10px;
    }
    .ml_10 {
      margin-left: 10px;
    }
    .balance {
      height: 100px;
      background: rgba(0, 0, 0, 0.03);
      border-radius: 3px;
      padding: 20px 15px;
      .current_balance {
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 22px;
        margin-bottom: 10px;
      }
      .USD {
        font-size: 21px;
        font-weight: 600;
        color: #ff2379;
        line-height: 29px;
        .text_USD {
          margin-right: 10px;
        }
      }
    }
    .elaborate {
      margin-top: 20px;
      margin: 12px;
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 22px;
    }
    .credit_debit_PayPal {
      width: 300px;
      height: 176px;
      background: #ffffff;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .disabled {
      background-color: rgba(0, 0, 0, 0.05);
      border-color: rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.3);
      .text {
        color: rgba(0, 0, 0, 0.3);
      }
      &:hover {
        cursor: not-allowed;
      }
    }

    .add {
      width: 40px;
      height: 40px;
      // border: 1px dashed rgba(0, 0, 0, 0.5);
      margin: 43px auto 20px;
      .icon-a-lianhe15 {
        font-size: 40px;
      }
    }
    .text {
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      text-align: center;
    }
    .info {
      margin: 20px 0;
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 22px;
    }
    .balance_info {
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 22px;
      margin-bottom: 40px;
    }
  }
}

// @media screen and (max-width: 1200px)  {
//   .w1120 {
//     background: red !important;
//   }
// }
</style>
