<template>
  <el-dialog :width="width600" :before-close="handleClose">
    <template #title custom-class="title">
      <div class="title">Delete account</div>
    </template>
    <div class="info_box">
      <div class="m_b_20">
        You have the right to be “Delete 99diy account”. If you are choosing to
        exercise that right, be aware that 99diy takes your data rights
        seriously.
      </div>
      <div class="m_b_20">
        Deleting your personal data from 99diy is an irreversible process. We
        will no longer be able to access your account, access any of your stored
        artwork or pull any invoices or records from our system as they will be
        permanently deleted.
      </div>
      <div>
        Deleting your account will not cancel any pending orders or charges. I
        understand and want to delete my data.
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="handleClose">Cancel</el-button>
        <el-button type="info" @click="handleDelete" :loading="isLoading">Delete</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref } from "vue";
import { sendDeleteUserCode } from '@/api/account'
import { ElMessage } from "element-plus";

const width600 = ref("600px");
const isLoading = ref(false)
const $emit = defineEmits(['input', 'check'])

const handleClose = () => {
  $emit('input', false)
}

const handleDelete = async () => {
  try {
    isLoading.value = true
    const { message } = await sendDeleteUserCode()
    ElMessage.success(message)
    handleClose()
    $emit('check', true)
  } catch (e) {
    ElMessage.error(e)
  } finally {
    isLoading.value = false
  }
}
</script>

<style scoped lang="scss">
.title {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.85);
}
.info_box {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  line-height: 22px;
  padding: 10px;
}
.m_b_20 {
    margin-bottom: 20px;
}
</style>
